import React from "react";
import Slider from "react-slick-adaptive-height-fixed";

import testimonials from "./testimonials";

import "./testimonial.scss";

export default function SimpleSlider() {
  return (
    <section className="testimonials wrapper bg-light">
      <div className="container py-10 py-md-12">
        <h2 className="display-4 mb-3 text-center">Happy Customers</h2>
        <p className="lead text-center mb-6 px-md-16 px-lg-0">
          Customer satisfaction is our major goal. See what our customers are
          saying about us.
        </p>
        <div className="position-relative">
          <div
            className="shape z-index-bg bg-dot primary rellax w-16 h-16"
            data-rellax-speed="1"
            style={{ top: "-1rem", left: "-1.7rem" }}
          />
          <div className="slider-wrapper sm">
            <Slider
              dots={true}
              infinite={true}
              autoplay={true}
              speed={250}
              autoplaySpeed={4000}
              cssEase="linear"
              slidesToShow={1}
              slidesToScroll={1}
              adaptiveHeight
            >
              {testimonials.map(({ text, name, image, designation }, idx) => (
                <div className="item" key={idx}>
                  <div className="item-inner">
                    <div className="card">
                      <div className="card-body">
                        <blockquote className="icon mb-0">
                          <p>“{text}”</p>
                          <div className="blockquote-details">
                            <img
                              className="rounded-circle w-12"
                              src={image}
                              alt=""
                            />
                            <div className="info">
                              <h5 className="mb-1">{name}</h5>
                              <p className="mb-0">{designation}</p>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-wrapper md">
            <Slider
              dots={true}
              infinite={true}
              autoplay={true}
              speed={250}
              autoplaySpeed={6000}
              cssEase="linear"
              slidesToShow={2}
              slidesToScroll={1}
              adaptiveHeight
            >
              {testimonials.map(({ text, name, image, designation }, idx) => (
                <div className="item" key={idx}>
                  <div className="item-inner">
                    <div className="card">
                      <div className="card-body">
                        <blockquote className="icon mb-0">
                          <p>“{text}”</p>
                          <div className="blockquote-details">
                            <img
                              className="rounded-circle w-12"
                              src={image}
                              alt=""
                            />
                            <div className="info">
                              <h5 className="mb-1">{name}</h5>
                              <p className="mb-0">{designation}</p>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider-wrapper xl">
            <Slider
              dots={true}
              infinite={true}
              autoplay={true}
              speed={250}
              autoplaySpeed={6000}
              cssEase="linear"
              slidesToShow={3}
              slidesToScroll={1}
              adaptiveHeight
            >
              {testimonials.map(({ name, text, designation, image }, idx) => (
                <div key={idx} className="item">
                  <div className="item-inner">
                    <div className="card">
                      <div className="card-body">
                        <blockquote className="icon mb-0">
                          <p>“{text}”</p>
                          <div className="blockquote-details">
                            <img
                              className="rounded-circle w-12"
                              src={image}
                              alt=""
                            />
                            <div className="info">
                              <h5 className="mb-1">{name}</h5>
                              <p className="mb-0">{designation}</p>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
