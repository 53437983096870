import React from "react";
import { Accordion } from "react-bootstrap";
import "./whyus.scss";
import Image from "../../../images/HomePage/Services.jpeg";
import { Link } from "gatsby";

export default () => {
  return (
    <section className="why-us wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row gx-lg-8 gx-xl-12 gy-10">
          <div className="col-lg-7 order-lg-2">
            <figure>
              <img className="w-auto" src={Image} srcSet={Image} alt="" />
            </figure>
          </div>
          <div className="col-lg-5">
            <h3 className="display-5 mb-7">Services we offer</h3>
            <Accordion>
              <h2 className="fs-15 text-uppercase text-primary mb-3">
                Real-Time Services
              </h2>
              <Accordion.Item eventKey="0">
                <Accordion.Header>RTK Corrections</Accordion.Header>
                <Accordion.Body className="pb-0">
                  <p>
                    We provide differential GNSS, single based Real Time
                    Kinematic (RTK) and Network Real Time Kinematic (NRTK) for
                    real time positioning applications.
                  </p>
                  <Link
                    to={"/user/new-connection?redirectTo=register"}
                    className="btn btn-primary rounded-pill mb-2"
                    target="_blank"
                    rel={"noreferrer"}
                  >
                    Get New Connection
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <h2 className="fs-15 text-uppercase text-primary mb-3">
                Post Processing Services
              </h2>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Post Processing</Accordion.Header>
                <Accordion.Body className="pb-0">
                  You need only upload your raw data files with the relevant
                  antenna and receiver details.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Rinex Data</Accordion.Header>
                <Accordion.Body className="pb-0">
                  We provide Reference Stations Raw data for Post Processing
                  requirements.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
