import React from "react";
import { Link } from "gatsby";
import { Accordion } from "react-bootstrap";
import "./faq.scss";

export const FAQ = () => {
  return (
    <section className="faq-home wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="w-lg-50 ms-auto me-auto mb-12">
          <h2 className="fs-15 text-uppercase text-primary mb-3 text-center">
            FAQs
          </h2>
          <h3 className="display-5 mb-4 ms-auto me-auto text-center">
            Find your answer here.
          </h3>
          <p className="lead mb-6 text-center">
            If you don't find the answer here, you can always contact us.
          </p>
          <div className="text-center">
            <Link to="/contact" className="btn btn-primary rounded-pill">
              Ask your question
            </Link>
          </div>
        </div>
        <div className="row gx-lg-8 gx-xl-12 gy-10 mt-md-5">
          <div className="col-lg-6 mt-0">
            <div className="accordion-wrapper">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is CORSNET?</Accordion.Header>
                  <Accordion.Body>
                  CORSnet<span className={"trademark"}>TM</span>, the Most Trusted
                  RTK network in Sri Lanka is the first of its kind started in
                  2014. It is now the largest RTK network in Sri Lanka covering
                  the whole Island. Established and managed by professionals of
                  the industry, CORSnet is now being used by many sectors such as
                  Surveying, Construction, GIS, Drone Operations, Agri machinery,
                  and many more. Clients with Ntrip enabled devices can receive cm
                  level accuracy in Real-Time from any location in Sri Lanka.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Who can use it?</Accordion.Header>
                  <Accordion.Body>
                    Anyone with a network ready (GPRS enabled) GPS receiver. All
                    connections are available through the internet via NTRIP and
                    TCP/IP protocols.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    In which areas can I use your service?
                  </Accordion.Header>
                  <Accordion.Body>
                    A complete list of covered areas is shown on the map{" "}
                    <a
                      href="/#hero"
                      rel={"noreferrer"}
                    >
                      here
                    </a>
                    .
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Where can I get connection information?
                  </Accordion.Header>
                  <Accordion.Body>
                    Assuming you have received your profile activation information you
                    can find connection information for your particular rover on
                    our Dashboard. If you do not have an account with us, please 
                    <Link to="/user/register"> register</Link>.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    I submitted a service request. But no one contacted me?
                  </Accordion.Header>
                  <Accordion.Body>
                    This process takes up to 24 hours normally. If you were not
                    contacted by our representative after 24 hours, please
                    recheck your profile and make sure that your email address
                    is correct. This is the address which we use to communicate
                    with you.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="col-lg-6 mt-0">
            <div className="accordion-wrapper">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How long do you keep Detailed Log Files?
                  </Accordion.Header>
                  <Accordion.Body>
                    Log files will be available for 90 days after completion.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    How do I access to CORSNET?
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>Gaining access to CORSnet has two simple steps.</p>
                    <ul>
                      <li>Register at CORSnet.</li>
                      <li>
                        Check your email inbox and click the emailed link by
                        CORSnet to activate your account.
                      </li>
                      <li>
                        Log in and navigate to Request Service section in
                        Dashboard.
                      </li>
                      <li>Fill the Request Connection Form & Pay.</li>
                    </ul>
                    <p>
                      After submitting the connection form, you will be
                      contacted by our representative. This can take up to 24
                      hours.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Why should I Register at CORSNET?
                  </Accordion.Header>
                  <Accordion.Body>
                    You need to{" "}
                    <Link to={"/user/register/"}>
                      {" "}
                      register (Get New Connection){" "}
                    </Link>{" "}
                    in order to gain access to CORSnet. Also, registering allows
                    you to monitor and manage all your requests and the
                    information about your ongoing projects such as the time
                    duration.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Can I gain access without registering?
                  </Accordion.Header>
                  <Accordion.Body>
                    You can't. You need to{" "}
                    <Link to={"/user/register/"}>
                      {" "}
                      register (Get New Connection){" "}
                    </Link>{" "}
                    first in order to gain access.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
