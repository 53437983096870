import Jayachandran from "../../../images/Testimony/Jayachandran.jpeg";
import Gotabhaya from "../../../images/Testimony/Gotabhaya.jpg";
import Udayakantha from "../../../images/Testimony/Udayakantha.jpeg";
import Bogahawatte from "../../../images/Testimony/Bogahawatta.jpeg";
import Ranjitha from "../../../images/Testimony/Ranjitha.jpeg";
import Dhananjaya from "../../../images/Testimony/Dhananjaya.jpg";
import Pushpakumara from "../../../images/Testimony/Pushpakumara.jpg";
import Mapalagama from "../../../images/Testimony/Mapalagama.jpeg";
import Pandikorale from "../../../images/Testimony/Pandikorale.jpeg";

const image =
  "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";

const testimonials = [
  {
    text: "I am used to work mostly in challenging ground terrains with hills and heavy canopy and CORSnet RTK service has greatly enhanced my work speed and productivity. 24 x 7 customer support from CORSnet team has been one of the best experience I had. ",
    name: "Mr. E. Jayachandran",
    designation: "Managing Director, B.Sc Surveying Sciences W & J Surveyors.",
    image: Jayachandran,
  },
  {
    text: "I was mostly used to Total Station Survey till I experience CORSnet powered GNSS RTK surveying technology. Now I find CORSnet service is very productive and easy to use technology. CORSnet professional team provides excellent support and they are very friendly and available anytime. Their field support is superb.",
    name: "Mr. Gotabaya Wanasinghe",
    designation: "Licensed Surveyor",
    image: Gotabhaya,
  },
  {
    text: "Have been using SULECO CORS Network for last couple of years in our field operations and found to be very reliable and dependable. We could complete many of our tasks even before the expected targets due to the support received from CORSNET. Wherever you go and whatever you do in Sri Lanka, positioning will be assured with CORSnet.",
    name: "Mr. Palitha Uadayakantha",
    designation: "Managing Director, Geospatial Lanka (Pvt) Ltd",
    image: Udayakantha,
  },
  {
    text: "In the Construction Field,  Surveying is one of key elements governing the quality of the work and project time line. Last year, we started using CORSnet RTK service to obtain precise SLD99 grid coordinates in real time for our Pipe Laying project. Since then surveying project progress and cost has always been at optimum levels. We are really happy with the CORSnet service and the professional support they are providing. ",
    name: "Mr. Janith Bogahawatta",
    designation: "Director, Finite Lanka (Pvt) Ltd",
    image: Bogahawatte,
  },
  {
    text: "CORSnet is a game changer for me as it enables me to conduct survey work efficiently. Rovers connected to CORSnet easily and it saves time on field data capturing. Very professional service, reasonable pricing and friendly team is highly commendable.",
    name: "Mr. Ranjitha Pathegama",
    designation: "Licensed Surveyor",
    image: Ranjitha,
  },
  {
    text: "I always work with the latest technologies using only the best equipment for my Surveying work. I confirm that the CORSnet RTK Service is very productive technology. It gives me prompt and precise geo coordinates in Centimeter level greatly enhancing my work speed. Good Pricing, technical support, professional team of the CORSnet has changed the traditional surveying process in Sri Lanka. ",
    name: "Mr. Sanjeewa Dhananjaya",
    designation: "Licensed Surveyor",
    image: Dhananjaya,
  },
  {
    text: "CORSnet RTK service has greatly enhanced our project productivity and service deliverables. For engineering surveys, feasibility studies, GIS applications users can effectively use this technology reducing costs associated compared to the traditional surveying methodologies. CORSnet team has always supported us on the field. They are very professional and very friendly. ",
    name: "Dr. T.D.C Pushpakumara ",
    designation: "Managing Director, Colombo Institute of Engineering Survey",
    image: Pushpakumara,
  },
  {
    text: "Conducting surveys quickly for the Road Design Work used to be a very challanging task, as precise XYZ coordinate transferring to the project site would take a lot of time, effort and cost. With CORSnet RTK services now we are effectively conducting our surveys. ",
    name: "Mr.  U S Mapalagama",
    designation: "RDA Surveyor",
    image: Mapalagama,
  },
  {
    text: "I have been using CORSnet RTK service for sometime now and I found it very effective to conduct my Drone survey work and to establish GCPs. I also experienced that the results are very good and this service is greatly enhancing the productively saving a lot of time and money. ",
    name: "Mr. Lalith Pandikorale",
    designation: "Licensed Surveyor",
    image: Pandikorale,
  },
];

export default testimonials;
