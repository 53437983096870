import React from "react";
import { Link } from "gatsby";
import Surveying from "../../../images/HomePage/KeyIndustries/Surveying.svg";
import GIS from "../../../images/HomePage/KeyIndustries/GIS.svg";
import Machine from "../../../images/HomePage/KeyIndustries/Machine.svg";
import Aerial from "../../../images/HomePage/KeyIndustries/Aerial.svg";

export const Services = ({ industries = [] }) => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-12">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-lg-7 order-2">
            <div className="row gx-md-5 gy-5">
              {industries.map((industry, idx) => {
                const classNames = [
                  "col-md-5 offset-md-1 align-self-end",
                  "col-md-6 align-self-end",
                  "col-md-5",
                  "col-md-6 align-self-start",
                ];
                return (
                  <div className={`${classNames[idx]} `} key={idx}>
                    <div className="card bg-pale-primary">
                      <div className="card-body">
                        <img
                          src={industry.icon.url}
                          className="icon-svg icon-svg-md text-yellow mb-3"
                          alt=""
                        />
                        <h4>{industry.title}</h4>
                        <p className="mb-0">{industry.summary}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/*<div className="col-md-6 align-self-end">*/}
              {/*  <div className="card bg-pale-primary">*/}
              {/*    <div className="card-body">*/}
              {/*      <img*/}
              {/*        src={GIS}*/}
              {/*        className="icon-svg icon-svg-md text-red mb-3"*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <h4>GIS in Mapping</h4>*/}
              {/*      <p className="mb-0">*/}
              {/*        With access to our DGNSS corrections, you simply set up*/}
              {/*        your GNSS rover and away you go.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="col-md-5">*/}
              {/*  <div className="card bg-pale-primary">*/}
              {/*    <div className="card-body">*/}
              {/*      <img*/}
              {/*        src={Machine}*/}
              {/*        className="icon-svg icon-svg-md text-leaf mb-3"*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <h4>Machine Control</h4>*/}
              {/*      <p className="mb-0">*/}
              {/*        To control your earthmoving equipment for precise digging,*/}
              {/*        grading and paving.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="col-md-6 align-self-start">*/}
              {/*  <div className="card bg-pale-primary">*/}
              {/*    <div className="card-body">*/}
              {/*      <img*/}
              {/*        src={Aerial}*/}
              {/*        className="icon-svg icon-svg-md text-primary mb-3"*/}
              {/*        alt=""*/}
              {/*      />*/}
              {/*      <h4>Aerial Surveying</h4>*/}
              {/*      <p className="mb-0">*/}
              {/*        Used in survey drones for positioning in real-time at the*/}
              {/*        cm-level when capturing photos of a site.*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="col-lg-5">
            <h2 className="fs-15 text-uppercase text-muted mb-3">
              Who we serve
            </h2>
            <h3 className="display-4 mb-5">Key Industries</h3>
            <p>
              CORSnet Being the largest network implemented in Sri Lanka for the
              first time for Real-Time Correction (RTK) broadcasting services,
              it is being used by many experts and organizations islandwide
              across several key industries.
            </p>
            <Link to="/industries" className="btn btn-navy rounded-pill mt-3">
              Read More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
