import React from "react";
import GoogleMapReact from "google-map-react";
import mapOptions from "./mapOptions";
import distanceToMouse from "./distanceToMouse";
import Marker from "./Marker";
import * as styles from "./index.module.scss";
import { graphql, useStaticQuery } from "gatsby";

const center = {
  lat: 7.8574889,
  lng: 80.8146277,
};

const desktopCenter = {
  lat: 7.8574889,
  lng: 79.0146277,
};

const isDesktop = typeof window !== "undefined" && window.innerWidth > 1200;

const Map = () => {
  const data = useStaticQuery(graphql`
    query markers {
      prismicCoverageMap {
        data {
          markers {
            name
            description
            is_active
            cordinates {
              latitude
              longitude
            }
          }
        }
      }
    }
  `);

  const markers = data.prismicCoverageMap.data.markers || [];

  return (
    <div className={styles.wrapper}>
      <div className={styles.text_wrapper}>
        <h1>Welcome to CORSnet</h1>
        {/* <p>
          The first and largest Continuous Observation Reference Station (CORS)
          Network in Sri Lanka
        </p> */}
        <p>Sri Lanka's first, islandwide, and most trusted CORS (Continuous Observation Reference Station) VRS RTK Correction Broadcasting Service.</p>
      </div>
      <div className="">
        <div className={`${styles.map_container}`}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
            hoverDistance={30}
            distanceToMouse={distanceToMouse}
            options={mapOptions}
            defaultCenter={center}
            zoom={8}
            center={isDesktop ? desktopCenter : center}
          >
            {markers.map((marker, idx) => {
              return (
                <Marker
                  data={marker}
                  lat={marker.cordinates.latitude}
                  lng={marker.cordinates.longitude}
                  key={idx}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
};

export default Map;
