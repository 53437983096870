import * as React from "react";
import Layout from "../layout";
import GoogleMap from "../components/HomePage/GoogleMap";
import { Facts } from "../components/HomePage/Facts/Facts";
import Testimonial from "../components/HomePage/Testimonials/Testimonial";
import { FAQ } from "../components/HomePage/FAQ/FAQ";
import { Services } from "../components/HomePage/Services/Services";
import WhyUs from "../components/HomePage/WhyUs/WhyUs";
import { graphql, useStaticQuery } from "gatsby";
import get from "lodash/get";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query homeIndustries {
      prismicIndustries {
        dataRaw
      }
    }
  `);

  const industries = get(
    data,
    "prismicIndustries.dataRaw.industries",
    []
  ).filter((item) => item.icon && item.summary);

  return (
    <Layout homePagecd>
      <section className="wrapper bg-light angled upper-end" id="hero">
        <div className="pb-0 pb-md-0">
          <section className="mb-8">
            <div className="mt-n14">
              <GoogleMap />
            </div>
          </section>
        </div>
      </section>
      <Facts />
      <Testimonial />
      <WhyUs />
      <Services industries={industries} />
      <FAQ />
    </Layout>
  );
};

export default IndexPage;
