function mapOptions(map) {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.LEFT_TOP,
    },
    // styles: mapStyles,
  };
}

export default mapOptions;
