const K_CIRCLE_SIZE = 200;
const K_STICK_SIZE = 220;

const distanceToMouse = (markerPos, mousePos, markerProps) => {
  const { x } = markerPos;
  // because of marker non symmetric,
  // we transform it central point to measure distance from marker circle center
  // you can change distance function to any other distance measure
  const y = markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;

  // and i want that hover probability on markers with text === 'A' be greater than others
  // so i tweak distance function (for example it's more likely to me that user click on 'A' marker)
  // another way is to decrease distance for 'A' marker
  // this is really visible on small zoom values or if there are a lot of markers on the map
  const distanceKoef = markerProps.text !== "A" ? 1.5 : 1;

  // it's just a simple example, you can tweak distance function as you wish
  return (
    distanceKoef *
    Math.sqrt(
      (x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y)
    )
  );
};

export default distanceToMouse;
