import React from "react";
import * as styles from "./index.module.scss";
import { FaMapPin as MapMarker } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Marker = ({ data }) => {
  const { name, description, is_active } = data;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className={styles.marker_hover_text}>
        <h6 className={"text-white"}>{name}</h6>
        <p>
          <small>{description}</small>
          <small className={"d-block mt-2"}>
            Status: {is_active ? "Active" : "Inactive"}
          </small>
        </p>
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <p
        className={`${styles.map_marker} ${
          is_active ? "" : styles.inactive_marker
        }`}
      >
        <MapMarker size={"36px"} />
      </p>
    </OverlayTrigger>
  );
};

export default Marker;

function nl2br(str) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  return (
    "<p>" +
    (str + "").replace(
      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
      "$1" + "</p><p>" + "$2"
    ) +
    "</p>"
  );
}
